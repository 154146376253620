import React from 'react';
import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';
import banner from '../../images/events/2025/nctc/WEC_event-banner-desktop.jpg';
import mobileBanner from '../../images/events/2025/nctc/WEC_event-banner-mobile.jpg';
import ogimage from '../../images/events/2025/nctc/WEC_event-OG.jpg';

export default function NCTCWinterEducationalConference2025() {
    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='NCTC Winter Educational Conference 2025' isEvents>
            <SEO
                title='NCTC Winter Educational Conference in Atlanta'
                ogDescription='Come see Witbe, new NCTC member, at the annual NCTC Winter Educational Conference.'
                description='Come see Witbe, new NCTC member, at the annual NCTC Winter Educational Conference.'
                article='article'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={18} banner={mobileBanner} /> : <DesktopEventPage id={18} banner={banner} />}
        </Layout>
    )
}
